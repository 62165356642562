import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import youTubeIcon from '../images/youtube-icon.png';
import rumbleIcon from '../images/rumble-icon.png';
import movieIcon from '../images/movie-icon.png';
import Avatar from '@mui/material/Avatar';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

export default function ChipsArray() {
  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Youtube', url: 'https://youtu.be/EgKGiXmgqZs' },
    { key: 1, label: 'Rumble', url: 'https://rumble.com/v3w2hi5-art-club.html' },
    { key: 2, label: 'Trailer', url: 'https://youtu.be/FbokR2mSVds' }
  ]);

  const onClickUrl = (url) => {
    return () => openInNewTab(url)
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData.map((data) => {
        let avatar;

        if (data.label === 'Youtube') {
            avatar= <Avatar src={youTubeIcon} />
        }

        if (data.label === 'Rumble') {
            avatar= <Avatar src={rumbleIcon} />
        }

        if (data.label === 'Trailer') {
            avatar= <Avatar src={movieIcon} />
        }

        return (
          <ListItem sx={{ paddingBottom:'.5rem' }} key={data.key}>
            <Chip
              avatar={avatar}
              label={data.label}
              onClick={onClickUrl(data.url)}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
}